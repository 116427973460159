import type { GuaranteesDescription } from '@orus.eu/backend/src/routers/contracts'
import type { BackofficeContractDescription } from '@orus.eu/backend/src/views/backoffice-contract-view'
import type { CustomerInformation } from '@orus.eu/backend/src/views/user-account-view'
import { useParams } from '@tanstack/react-router'
import { trpc } from '../../../../../client'
import { useApi } from '../../../../../lib/use-api/use-api'
import { GlobalLoadingState } from '../../../../molecules/global-loading-state'
import { ContractPageContent } from '../../common/backoffice-contract-page/contract-page-content'

export default function PartnersContractPage(): JSX.Element {
  const { subscriptionId, organization } = useParams({ from: '/partner/$organization/contracts/$subscriptionId' })

  const contractData = useApi(loadContractPageData, subscriptionId, organization)

  if (!contractData.ready) {
    return <GlobalLoadingState />
  }

  const { contract, customer, guaranteesDescription } = contractData.data

  return (
    <ContractPageContent
      type="partner"
      contract={contract}
      customer={customer}
      guaranteesDescription={guaranteesDescription}
    />
  )
}

async function loadContractPageData(
  subscriptionId: string,
  organization: string,
): Promise<{
  contract: BackofficeContractDescription
  customer: CustomerInformation
  guaranteesDescription: GuaranteesDescription
}> {
  const [contract, customer, guaranteesDescription] = await Promise.all([
    trpc.contracts.getPartnerContract.query({ subscriptionId, organization }),
    trpc.contracts.getPartnerContractCustomer.query({ subscriptionId, organization }),
    trpc.contracts.getPartnerContractGuarantees.query({ subscriptionId, organization }),
  ])

  return {
    contract,
    customer,
    guaranteesDescription,
  }
}
